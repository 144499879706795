import * as React from "react"
import { Helmet } from "react-helmet"
import Header from '../components/Header'
import Footer from '../components/Footer'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons'

export default () => {
  return (
    <main className="contactpage">
      <Helmet>
        <title>Contact - MOP Floorcheck</title>
        <link rel="canonical" href="https://mopfloorcheck.com/contact" />
      </Helmet>
      <Header tab="contact" />
      <section className="intro">
        <div className="intro-content">
          <h2>CONTACT US</h2>
          <hr />
          <p>We're here to help. Please feel free to contact us for any inquiries.</p>
        </div>
      </section>
      <section className="infobox-section">
        <div className="container">
          <div className="infobox row">
            <div className="col-lg">
              <FontAwesomeIcon icon={faHome} />
              <h3>Address</h3>
              <p>
                Michael Olsen & Partnere A/S<br />
                Smedeholm 10, 1. tv<br />
                2730 Herlev
              </p>
            </div>
            <div className="col-lg">
              <FontAwesomeIcon icon={faClock} />
              <h3>Business Hours</h3>
              <p>
                Monday-Thursday: 8.30am – 4.30pm<br />
                Friday: 8.30am – 4pm
              </p>
            </div>
            <div className="col-lg">
              <FontAwesomeIcon icon={faPhone} />
              <h3>Phone</h3>
              <p>
                Main Office: +45 44 64 15 10
              </p>
            </div>
            <div className="col-lg">
              <FontAwesomeIcon icon={faEnvelope} />
              <h3>Email</h3>
              <p>
                <a href="mailto:floorcheck@mop.dk">floorcheck@mop.dk</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  )
}
